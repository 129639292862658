import SectionContainer from "../components/SectionContainer";

export default function Home() {
  const CURSOR =
    "after:content-['⬞'] after:ml-1 after:w-4 after:bg-primaryTextLight after:dark:bg-primaryTextDark after:inline-block after:animate-pulse";

  return (
    <SectionContainer id="home">
      <h1 className={`text-2xl sm:text-4xl ${CURSOR}`}>
        hi, i'm <span className="dark:text-secondaryDark text-secondaryLight">jonathan</span>!
      </h1>
    </SectionContainer>
  );
}
