import Dot from "../components/Dot";
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";
import { Section } from "../App";

export const SECTIONS: Section[] = [
  { name: "home", sectionId: "#home", dotId: "homeDot" },
  { name: "about", sectionId: "#about", dotId: "aboutDot" },
  { name: "contact", sectionId: "#contact", dotId: "contactDot" },
  { name: "resume", sectionId: "#resume", dotId: "resumeDot" },
];

interface SidebarProps {
  currentSection: Section;
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

export default function Sidebar(props: SidebarProps) {
  const { currentSection, darkMode, setDarkMode } = props;

  const ICON_STYLE = "w-5 h-5 sm:w-5 sm:h-5 cursor-pointer hover:opacity-50 duration-300";

  function handleClickDarkMode() {
    setDarkMode(!darkMode);
  }

  return (
    <div className="fixed z-10 right-4 sm:right-6 flex flex-col top-[45%] gap-y-3 items-center">
      {darkMode ? (
        <SunIcon className={ICON_STYLE} onClick={handleClickDarkMode} />
      ) : (
        <MoonIcon className={ICON_STYLE} onClick={handleClickDarkMode} />
      )}
      {SECTIONS.map((section) => {
        return <Dot key={section.name} selected={section.name === currentSection.name} section={section} />;
      })}
    </div>
  );
}
