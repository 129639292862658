import SectionContainer from "../components/SectionContainer";
import LinkedInLight from "../images/social-media/linkedin-light.svg";
import LinkedInDark from "../images/social-media/linkedin-dark.svg";
import InstagramLight from "../images/social-media/instagram-light.svg";
import InstagramDark from "../images/social-media/instagram-dark.svg";
import GithubLight from "../images/social-media/github-light.svg";
import GithubDark from "../images/social-media/github-dark.svg";
import SocialMediaIcon from "../components/SocialMediaIcon";

const INSTAGRAM_LINK = "https://www.instagram.com/jonathanchen7/";
const LINKEDIN_LINK = "https://www.linkedin.com/in/jonathanchen7/";
const GITHUB_LINK = "https://www.github.com/jonathanchen7/";

interface ContactProps {
  darkMode: boolean;
}

export default function Contact(props: ContactProps) {
  const { darkMode } = props;

  return (
    <SectionContainer id="contact">
      <div className="flex flex-col items-center gap-y-4">
        <h2 className="text-2xl">contact</h2>
        <p className="text-secondaryLight dark:text-secondaryDark">jonathanschen7[at]gmail.com</p>
        <div className="flex items-center gap-x-4">
          <SocialMediaIcon image={darkMode ? InstagramLight : InstagramDark} alt="instagram" link={INSTAGRAM_LINK} />
          <SocialMediaIcon image={darkMode ? LinkedInLight : LinkedInDark} alt="linkedin" link={LINKEDIN_LINK} />
          <SocialMediaIcon image={darkMode ? GithubLight : GithubDark} alt="github" link={GITHUB_LINK} />
        </div>
      </div>
    </SectionContainer>
  );
}
