import SectionContainer from "../components/SectionContainer";

export default function About() {
  return (
    <SectionContainer id="about">
      <div>
        <h2 className="text-2xl mb-4">about me</h2>
        <p className="text-sm sm:text-md">- seattle-based full stack engineer</p>
        <p className="text-sm sm:text-md">- currently working @ modern treasury</p>
        <p className="text-sm sm:text-md">- formerly @ amazon and docugami</p>
        <p className="text-sm sm:text-md">- university of washington computer science graduate</p>
      </div>
    </SectionContainer>
  );
}
