import React from "react";

interface SocialMediaIconProps {
  image: string;
  alt: string;
  link: string;
}

export default function SocialMediaIcon(props: SocialMediaIconProps) {
  const { image, alt, link } = props;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <img className="w-8 hover:opacity-75 hover:cursor-pointer duration-300" src={image} alt={alt} />
    </a>
  );
}
