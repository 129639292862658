import React from "react";

interface SectionContainerProps {
  id?: string;
}

export default function SectionContainer(props: React.PropsWithChildren<SectionContainerProps>) {
  const { id, children } = props;

  return (
    <div
      id={id}
      className="bg-primaryLight dark:bg-primaryDark h-screen p-10 sm:px-16 sm:py-12 flex items-center justify-center"
    >
      {children}
    </div>
  );
}
