import SectionContainer from "../components/SectionContainer";
import ResumePDF from "../files/jonathan-chen-resume.pdf";
import ResumeYAML from "../files/jonathan-chen-resume.yml";

export default function Resume() {
  return (
    <SectionContainer id="resume">
      <div className="flex flex-col justify-center gap-y-6 sm:gap-y-8">
        <div className="text-center">
          <a className="text-xl hover:opacity-75 duration-300" href={ResumePDF} download="jonathan-chen-resume.pdf">
            resume 💼
          </a>
        </div>
        <div className="text-center">
          <div className="hover:animate-wiggle">
            <a
              className="text-xl text-secondaryLight dark:text-secondaryDark"
              href={ResumeYAML}
              download="jonathan-chen-resume.yml"
            >
              resume 💃🕺
            </a>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
}
