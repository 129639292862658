import { Tooltip as ReactTooltip } from "react-tooltip";
import { Section } from "../App";

interface TooltipProps {
  section: Section;
}

export default function Tooltip(props: TooltipProps) {
  const { section } = props;
  return (
    <ReactTooltip
      noArrow
      clickable
      id={section.dotId}
      place="left"
      opacity={0.8}
      style={{
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 0.5,
        paddingBottom: 0.5,
        fontSize: "small",
        borderRadius: 4,
        backgroundColor: "black",
        marginRight: 0,
      }}
    >
      {section.name}
    </ReactTooltip>
  );
}
