import React from "react";
import { Section } from "../App";
import BottomNavigationArrow from "../components/BottomNavigationArrow";
import { SECTIONS } from "./Sidebar";
import TopNavigationArrow from "../components/TopNavigationArrow";

interface NavigationArrowsProps {
  currentSection: Section;
}

export default function NavigationArrows(props: NavigationArrowsProps) {
  const { currentSection } = props;

  return (
    <>
      {currentSection !== SECTIONS[0] && <TopNavigationArrow currentSection={currentSection} />}
      {currentSection !== SECTIONS[SECTIONS.length - 1] && <BottomNavigationArrow currentSection={currentSection} />}
    </>
  );
}
