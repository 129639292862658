import { Section } from "../App";
import Tooltip from "./Tooltip";

interface DotProps {
  selected?: boolean;
  section: Section;
}

export default function Dot(props: DotProps) {
  const { selected, section } = props;

  return (
    <>
      <a
        href={section.sectionId}
        data-tooltip-id={section.dotId}
        className={`h-2.5 w-2.5 rounded-full bg-primaryTextLight dark:bg-primaryTextDark cursor-pointer hover:bg-secondaryLight hover:dark:bg-secondaryDark hover:opacity-100 ${
          !selected && "opacity-50"
        } duration-500`}
      >
        {" "}
      </a>
      <Tooltip section={section} />
    </>
  );
}
