import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { Section } from "../App";
import { SECTIONS } from "../sections/Sidebar";

interface TopNavigationArrowProps {
  currentSection: Section;
}

export default function TopNavigationArrow(props: TopNavigationArrowProps) {
  const { currentSection } = props;

  function previousSectionLink() {
    const currentSectionIdx = SECTIONS.indexOf(currentSection);

    if (!currentSectionIdx) return "";

    return SECTIONS[currentSectionIdx - 1]?.sectionId || "";
  }

  return (
    <div className="fixed top-0 w-full flex justify-center">
      <a className="w-1/2 sm:w-3/4 py-6 flex justify-center" href={previousSectionLink()}>
        <ChevronUpIcon className="w-6 animate-pulse" />
      </a>
    </div>
  );
}
