import { useEffect, useState } from "react";
import Header from "./sections/Header";
import Home from "./sections/Home";
import Sidebar, { SECTIONS } from "./sections/Sidebar";
import About from "./sections/About";
import Contact from "./sections/Contact";
import NavigationArrows from "./sections/NavigationArrows";
import Resume from "./sections/Resume";
import Footer from "./sections/Footer";

export type SectionName = "home" | "about" | "contact" | "resume";

export interface Section {
  name: SectionName;
  sectionId: string;
  dotId: string;
}

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [currentSection, setCurrentSection] = useState<Section>(SECTIONS[0]);

  useEffect(() => {
    darkMode ? document.documentElement.classList.add("dark") : document.documentElement.classList.remove("dark");
  }, [darkMode]);

  useEffect(() => {
    function handleScroll() {
      const currentSection = calculateCurrentSection();
      setCurrentSection(SECTIONS[currentSection - 1]);
    }

    function calculateCurrentSection() {
      const sectionHeight = window.innerHeight;
      const sectionNumber = Math.ceil((window.scrollY + sectionHeight / 2) / sectionHeight);
      return Math.max(1, sectionNumber);
    }

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Detach the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-primaryLight dark:bg-primaryDark text-primaryTextLight dark:text-primaryTextDark flex flex-col duration-300">
      <Header darkMode={darkMode} />
      <Sidebar currentSection={currentSection} darkMode={darkMode} setDarkMode={setDarkMode} />
      <NavigationArrows currentSection={currentSection} />
      <Home />
      <About />
      <Contact darkMode={darkMode} />
      <Resume />
      <Footer />
    </div>
  );
}

export default App;
