import LogoLight from "../images/logo-light-transparent.png";
import LogoDark from "../images/logo-dark-transparent.png";

interface HeaderProps {
  darkMode: boolean;
}

export default function Header(props: HeaderProps) {
  const { darkMode } = props;

  return (
    <div className="z-50 fixed top-0 flex p-5 sm:p-6">
      <a className="cursor-pointer" href="#home">
        <img className="h-4 hover:opacity-75 duration-300" src={darkMode ? LogoLight : LogoDark} alt="logo" />
      </a>
    </div>
  );
}
